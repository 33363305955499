<mat-toolbar color="primary" class="mat-elevation-z6">
  <a [routerLink]="['']">
    <img src="assets/img/logo/logo_white.png" class="logo p-2" alt="">
  </a>

  <div class="spacer"></div>

  <a mat-button [matMenuTriggerFor]="menu">Inicio</a>
  <a mat-button [matMenuTriggerFor]="cursos">Cursos</a>
  <a mat-button [routerLink]="['/servicios']">Servicios</a>
  <a mat-button [routerLink]="['contactenos']">Contáctanos</a>

  <div class="spacer"></div>
  <!-- <a mat-raised-button class="buttons-toolbar" color="accent" [routerLink]="['directorio/registrar']"
    title="Resgístrate">Registrarme</a>
  <a mat-raised-button class="buttons-toolbar login-button" routerLink="http://localhost:4200/"
    title="Ingresa a tu cuenta">Log In</a> -->
</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item [routerLink]="['']">Inicio</button>
  <button mat-menu-item [routerLink]="['about']">Nosotros</button>
</mat-menu>
<mat-menu #cursos="matMenu" xPosition="after">
  <button mat-menu-item [routerLink]="['/cursos/full-stack-developer']">Full Stack Developer</button>
  <!-- <button mat-menu-item [routerLink]="['/cursos/microsoft-excel']">Microsoft Excel</button> -->
</mat-menu>
<!-- <mat-menu #menu_serv="matMenu" xPosition="after">
  <button mat-menu-item [routerLink]="['/servicios/desarrolloweb']">Desarrollo de aplicaciones Web</button>
  <button mat-menu-item [routerLink]="['/servicios/desarrolloapp']">Desarrollo de aplicaciones Móviles</button>
  <button mat-menu-item [routerLink]="['/servicios']">Directorio</button>
</mat-menu> -->